import React from "react"

// Library components
import Fade from 'react-reveal/Fade'
import Col from 'react-bootstrap/Col'

function Title({ title}) {

    return (
        <Col lg={12} className="pb-4">
          <Fade up>
            <h2>{title}</h2>
            {/* <Line /> */}
          </Fade>
        </Col>
    )
}

Title.defaultProps = {
    title: "Title"
}

export default Title
import React from "react"
import { Modal } from "react-bootstrap"

const ModalLocation = ({ show, onHide, data, currentCity, currentCountry, tags }) => {

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <span></span>
      </Modal.Header>
      <Modal.Body>
        <div className="location-modal">
          <div className="container-fluid">
            <div className="row no-gutters">
              <div className="col-sm-12 location-modal-cover location-modal--body">
                <img className="img-fluid" src={data[currentCountry].list[currentCity].image.childImageSharp.fluid.src} alt={data[currentCountry].list[currentCity].title} />
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 location-modal--body">
                    <h2 className="location-modal-title">{data[currentCountry].list[currentCity].city}</h2>
                    <hr className="location-modal-line" />
                    <h4>{data[currentCountry].list[currentCity].month} {data[currentCountry].list[currentCity].date}</h4>
                    <br />
                    {data[currentCountry].list[currentCity].usa ? <>
                    <h3>ENTRADAS:</h3>
                    <br /><span className="text-primary">BLACK VIP $2,000.00</span>
                    <br />
                    <br />1- ¡Solo 20 cupos disponibles!
                    <br />FRENTE A FRENTE (PRIMERA FILA) AMBOS DÍAS DEL EVENTO
                    <br />MASTER CLASS + HANDS ON
                    <br />INCLUYE EL TRÍPODE DE REGALO
                    <br />INCLUYE TODOS LOS PRODUCTOS PARA TRABAJAR EN LA CLASE
                    <br />DESPIÉRTATE CON ENERGÍA & ESTILO (DESAYUNO VIP CON GABRIEL)
                    <br />BACKPACK CON OBSEQUIOS VALORADO EN $500,00.
                    <br />T-SHIRT DE REGALO “EL ARTE DEL BALAYAGE”
                    <br />(EL CERTIFICADO SE ENTREGA EL SEGUNDO DÍA).
                    <br />DEBES TRAER:
                    <br />
                    <br />3 PARES DE GUANTES.
                    <br />BROCHAS, PEINES Y TAZAS.
                    <br />TOALLAS (DE 3 A 4).
                    <br />HAIR CLIPS.
                    <br />
                    <br />CronogramaMaster Class (Primer día)
                    <br />8:30am – Desayuno9:30 am – Vive Samra (Inicio de la MasterClass)
                    <br />
                    <br />12:30pm – Almuerzo
                    <br />1:30pm – Colorimetría
                    <br />2:15pm – Redes Sociales y Asesoría.
                    <br />3:30pm – Motivación
                    <br />4:30pm – 5:30 pm – Show final y fotos.
                    <br />
                    <br />Hands-On (Segundo día)
                    <br />8:30am – Desayuno VIP con Gabriel Samra (Black VIP).
                    <br />10:00am – Manos a la obra
                    <br />1:00pm – Almuerzo
                    <br />2:00pm – Continuación Manos a la obra
                    <br />5:30pm – Terminas tu obra, fotos y entrega de certificados.
                    <br />
                    <br />
                    <br /><span className="text-primary">SILVER ACCESS $,1400.00</span>
                    <br />
                    <br />2 - 25 A 35 CUPOS
                    <br />AMBOS DÍAS DE CLASE (MASTER CLASS + HANDS ON CLASS)
                    <br />BOLSA DE REGALOS VALORADO EN $300,00
                    <br />T-SHIRT DE REGALO “EL ARTE DEL BALAYAGE”
                    <br />(EL CERTIFICADO SE ENTREGA EL SEGUNDO DÍA).
                    <br />
                    <br />DEBES TRAER:
                    <br />
                    <br />3 PARES DE GUANTES.
                    <br />BROCHAS, PEINES Y TAZAS.
                    <br />TOALLAS (DE 3 A 4).
                    <br />TRÍPODE.
                    <br />HAIR CLIPS.
                    <br />CronogramaMaster Class (Primer día)
                    <br />8:30am – Desayuno9:30 am – Vive Samra (Inicio de la MasterClass)
                    <br />
                    <br />12:30pm – Almuerzo
                    <br />1:30pm – Colorimetría
                    <br />2:15pm – Redes Sociales y Asesoría.
                    <br />3:30pm – Motivación
                    <br />4:30pm – 5:30 pm – Show final y fotos.
                    <br />
                    <br />Hands-On (Segundo día)
                    <br />8:30am – Desayuno.
                    <br />10:00am – Manos a la obra
                    <br />1:00pm – Almuerzo
                    <br />2:00pm – Continuación Manos a la obra
                    <br />5:30pm – Terminas tu obra, fotos y entrega de certificados.
                    <br />
                    <br />
                    <br />
                    <br /><span className="text-primary">GOLDEN TICKET: $500.00</span>
                    <br />
                    <br />3 - 50 A 55 CUPOS.
                    <br />MASTER CLASS LOOK & LEARN. (SERÁ EL PRIMER DÍA DEL EVENTO).
                    <br />BOLSA CON OBSEQUIOS.
                    <br />T-SHIRT DE REGALO “EL ARTE DEL BALAYAGE”
                    <br />FOTO CON Gabriel Samra.
                    <br />ENTREGA DE CERTIFICADO.
                    <br />
                    <br />Cronograma:Master Class (Primer día)
                    <br />
                    <br />8:30am – Desayuno
                    <br />9:30 am – Vive Samra (Inicio de la MasterClass)
                    <br />12:30pm – Almuerzo
                    <br />1:30pm – Colorimetría
                    <br />2:15pm – Redes Sociales y Asesoría.
                    <br />3:30pm – Motivación
                    <br />4:30pm – 5:30 pm – Show final y fotos.
                    <br />
                    <br />
                    <div className="location-modal-subtitle"><i className="fas fa-phone fa-rotate-90 mr-2 text-primary"></i><span className="text-primary">Teléfono:</span><span> (305) 497-9448</span></div>
                    <div className="location-modal-subtitle"><i className="fas fa-envelope mr-2 text-primary"></i><span className="text-primary">Email:</span><span> info@tourgabrielsamra.com</span></div>
                    </> : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="quote-wrapper  text-center">
          <h2 className="quote-text">ADQUIERE TUS ENTRADAS<a href={data[currentCountry].list[currentCity].url} target="_blank" rel="noreferrer"><button className="btn-outline ml-3">Comprar tickets</button></a></h2>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalLocation
import React from "react"
import Modal from "./Modal"
import Fade from 'react-reveal/Fade'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
export default class Location extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      modalCityCurrent: 0,
      modalCountryCurrent: 0
    }
    this.handleModalClick = this.handleModalClick.bind(this);
    this.setModal = this.setModal.bind(this);
  }

  handleModalClick(CityId, CountryId, e) {
    e.preventDefault();
    this.setModal(true, CityId, CountryId);
  }

  setModal(isShown, currentCity, currentCountry) {
    this.setState({
      modalShow: isShown,
      modalCityCurrent: currentCity,
      modalCountryCurrent: currentCountry
    });
  }

  render() {

    return (
      <>
        {this.props.data.country.map((country, countryId) =>
          <Container key={countryId}>
            <Row>
              {country.list.map((city, cityId) =>
                <Col className="mb-3" key={cityId} md={4} lg={3}>
                <Fade >
                    <button className="btn btn-clean grid-row-box" onClick={this.handleModalClick.bind(this, cityId, countryId)} target="_blank" rel="noopener noreferrer">
                      <img src={city.image.childImageSharp.fluid.src} alt={city.title} />
                      <div className="grid-row-box-over">
                        {/* <i className="fas fa-search-plus"></i> */}
                        <span className="month">{city.month}</span>
                        <span className="date">{city.date}</span>
                        <hr className="line" />
                        <span className="city">{city.city}</span>

                      </div>
                    </button>
                    {/* <button className="btn btn-outline btn-full" onClick={this.handleModalClick.bind(this, cityId, countryId)}>{city.title}</button> */}
                  </Fade>
                </Col>
              )}
              </Row>
            </Container>
        )}
        <Modal show={this.state.modalShow}
          onHide={() => this.setModal(false, 0, 0)}
          data={this.props.data.country}
          currentCity={this.state.modalCityCurrent}
          currentCountry={this.state.modalCountryCurrent}
          tags={this.props.data.tags}
        >
        </Modal>
      </>
    )
  }
}
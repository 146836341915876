import React from "react"

// Libraries 
import { graphql } from "gatsby"
// import { CookiesProvider } from 'react-cookie';
import Img from 'gatsby-image'
import Col from 'react-bootstrap/Col'
import Fade from 'react-reveal/Fade'
// import Accordion from 'react-bootstrap/Accordion'
// import Card from 'react-bootstrap/Card'
// import Button from 'react-bootstrap/Button'

// Core components
import Title from "../components/Common/Title"
// import List from "../components/Common/List"
import Section from "../components/Common/Section"
import Layout from "../components/Layout.js"
import Seo from "../components/Seo.js"
import Hero from "../components/Hero/Hero.js"
import Location from "../components/Location/Location.js"
// import GridRowItem from "../components/GridRowItem"
import GalleryItem from "../components/Gallery/GalleryItem.js"
// import GridColumnItem from "../components/GridColumnItem.js"
// import Services from "../components/Services.js"
// import Contact from "../components/Contact.js"
// import Form from "../components/Form.js"
// import CookiesConsent from "../components/Cookies.js"

import _aboutBg from '../data/images/about_bg.jpg'
import _bienvenido from '../data/images/bienvenido.png'
import _calendar from '../data/images/calendario.png'

function IndexPage(props) {

  const contentJson = props.data.allDataJson.edges[0].node
  return (
    <Layout>

      <Seo page={'inicio'} title={contentJson.general.title} lang={contentJson.general.lang} metas={contentJson.general} image={contentJson.general.seo_picture.publicURL} />

      <Hero data={contentJson.hero} />
      {/* <Slider data={contentJson.hero} /> */}

      <section anchor={'quote'} className='quote container-fluid'>
        <div className="quote-wrapper">
          <Fade>
            <h2 className="quote-text">Más del Tour: Gabriel Samra 2022</h2>
          </Fade>
          <Fade>
            <a href="https://instagram.com/tourgabrielsamra?igshid=YTM0ZjI4ZDI=" target="_blank" rel="noreferrer"><button className="btn-outline">Ver más</button></a>
          </Fade>
        </div>
      </section>

      <Section anchor={'about'} className={'about position-relative'} style={{ background: 'linear-gradient(to right bottom, rgba(0,0,0,0), rgba(0,0,0,0)), url(' + _aboutBg + ') 40% 0', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <img className="about-bienvenido" src={_bienvenido} alt="bienvenido" />
        <Col xs={12} md={6} lg={8}>
            <Title title={contentJson.about.title} />
            <div className="mb-4"> {contentJson.about.text.split('\n').map((item, i) => <Fade key={i}><p>{item}</p></Fade>)}</div>
        </Col>
        <Col xs={12} md={6} lg={4} align="text-center box-image">
          <Fade right>
            <Img className="fluido" fluid={contentJson.about.image.childImageSharp.fluid} alt="profile" />
          </Fade>
        </Col>
      </Section>

      <Section anchor={'location'} className={'location py-5'} fluid={'fluid'} noGutters={'noGutters'}>
        <Col xs={12} className="text-center"><img className="location-calendar" src={_calendar} alt="calendar" /></Col>
        <Col xs={12}><Location data={contentJson.location} /></Col>
      </Section>

      <Section anchor={'gallery'} className={'gallery'} fluid={'true'} noGutters={'true'} >
        <GalleryItem image={contentJson.gallery.images} />
      </Section>

      <section anchor={'quote'} className='quote container-fluid'>
        <Fade>
          <div className="quote-wrapper">
            <h2 className="quote-text text-center">NO TE QUEDES SIN TU ENTRADA<a href="https://www.eventbrite.com/o/gabriel-samra-34149443413" target="_blank" rel="noreferrer"><button className="btn-outline ml-3">Comprar tickets</button></a></h2>
          </div>
        </Fade>
      </section>

      {/* <CookiesProvider>
        <CookiesConsent data={contentJson.tags} />
      </CookiesProvider> */}
    </Layout>
  )
}

export default IndexPage


export const imageData = graphql`
  query{
    allDataJson(filter: {general: {lang: {eq: "es"}}}) {
      edges {
        node {
        ...generalFields
        ...heroFields
        ...galleryFields
        ...aboutFields
        ...locationFields
        ...tagsFields
        }
      }
    }  
  }`

import React from "react"
import Zoom from 'react-reveal/Zoom';
import GalleryModal from "./Modal"
import GalleryCarousel from "./Carousel"

export default class createGallery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      modalCurrent: 0
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this);
    this.setModal = this.setModal.bind(this);
  }

  handlePortfolioClick(index, e) {
    e.preventDefault();
    this.setModal(true, index);
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current
    });
  }

  render() {
    const images = this.props.image
    return (
      <>
        {images.map((key, index) =>
          <Zoom key={index}>
          <div className="col-sm-6 col-lg-3 grid-row px-0">
              {key.image !== undefined ?
              <div className="col-12 px-0">
                  <button className="grid-row-box grid-row-box-shadow box-image" onClick={this.handlePortfolioClick.bind(this, index)}>
                      <img src={key.image.childImageSharp.fluid.src} className="fluido" alt={key.name} />
                      <div className="grid-row-box-over"></div>
                  </button>
              </div> : ''}                  
          </div>
      </Zoom>
        )}
        <GalleryModal show={this.state.modalShow} onHide={() => this.setModal(false, 0)}>
          <GalleryCarousel images={images} current={this.state.modalCurrent} />
        </GalleryModal>
      </>
    )
  }
}